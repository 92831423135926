import React, { useReducer, useEffect, useRef, useState } from 'react';
import Parse from 'parse';
import { IonContent, IonModal, IonPage } from "@ionic/react";
import { ChevronLeft, PersonAddAlt1 } from "@mui/icons-material";
import { useSeamUser } from "../utils/SeamUserContext";
import Header from "../Navigation/Header";
import Feed from '../components/Feed';
import { LoadingSkeleton } from '../components/LoadingSkeleton';
import { useHistory } from 'react-router';
import ChannelFeedHeader from './ChannelFeedHeader';
import { Typography } from '@mui/material';
import { Seo } from '../utils/Seo';
import ChannelPageModal from './ChannelPageModal';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import SeamComposer from "../Composer/SeamComposer";
import CollectionArrow from "../assets/icons/collectionArrow.png";
import DesktopSidebarWrapper from '../components/DesktopSidebarWrapper';
import SeamChannelRepository from './SeamChannelRepository';
import { useMobile } from '../utils/MobileContext';

const initialState = {
  currentChannel: undefined,
  channelPicture: null,
  channelName: '',
  fontColor: '',
  selectedEmoji: '',
  color1: undefined,
  color2: undefined,
  isFollowing: false,
  loadingChannelData: false,
};

const channelReducer = (state, action) => {
  switch (action.type) {
    case "SET_CHANNEL_DATA":
      return {
        ...state,
        ...action.payload,
        loadingChannelData: false,
      };
    case "SET_FOLLOW_STATUS":
      return {
        ...state,
        isFollowing: action.payload,
      };
    case "SET_CHANNEL_LOADING":
      return {
        ...state,
        loadingChannelData: action.payload,
      };
    default:
      return state;
  }
};

const ChannelToastContent = ({ channel }) => {
  const { navigateTo } = useSeamNavigator();

  return (
    <div className="flex items-center justify-between h-auto w-auto">
      <h4 className="text-[#FEFEFE]/70 mr-2">You've added a post to</h4>
      <div
        onClick={() => navigateTo(`/collection/${channel.id}`, channel)}
        className="flex flex-row items-center justify-center cursor-pointer w-auto max-w-[50%] truncate"
      >
        <span className="no-underline">{channel.get("emoji")}</span>
        <h4 className="underline truncate w-full">{channel.get("name")}</h4>
      </div>
    </div>
  );
};

const ChannelPage = ({ channelId, takingScreenshot }) => {
  const { account, refreshAccount } = useSeamUser();
  const { contextObject } = useSeamNavigator();
  const history = useHistory();
  const initialChannel = contextObject?.className === "Channel" ? contextObject : undefined;

  const [state, dispatch] = useReducer(channelReducer, {
    ...initialState,
    currentChannel: initialChannel,
    channelPicture: initialChannel?.get("headerImage"),
    channelName: initialChannel?.get("name"),
    fontColor: initialChannel?.get("fontColor"),
    selectedEmoji: initialChannel?.get("emoji"),
    color1: initialChannel?.get("color1") || '#FAFAFA',
    color2: initialChannel?.get("color2") || undefined,
  });

  const [hasLoadedPosts, setHasLoadedPosts] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isChannelCreator = state.currentChannel?.get("creator")?.id === account?.id;
  const feedRef = useRef();

  const scrollToTop = () => {
    if (feedRef.current) {
      feedRef.current.scrollToTop();
    }
  };

  const fetchChannelData = async () => {
    dispatch({ type: "SET_CHANNEL_LOADING", payload: true });
    const Channel = Parse.Object.extend("Channel");
    const query = new Parse.Query(Channel);
    query.equalTo("objectId", channelId);
    query.include("creator");

    try {
      const channel = await query.first();
      if (channel) {
        dispatch({
          type: "SET_CHANNEL_DATA",
          payload: {
            currentChannel: channel,
            channelPicture: channel.get("headerImage"),
            channelName: channel.get("name"),
            fontColor: channel.get("fontColor"),
            selectedEmoji: channel.get("emoji"),
            color1: channel.get("color1"),
            color2: channel.get("color2"),
          },
        });
      }
    } catch (error) {
      console.error("Error fetching channel: ", error);
      dispatch({ type: "SET_CHANNEL_LOADING", payload: false });
    }
  };

  const fetchFollowingStatus = async () => {
    if (!account) return;
    const followedChannelsRelation = account.relation("followedChannels");
    const followedQuery = followedChannelsRelation.query();
    followedQuery.equalTo("objectId", channelId);
    const isFollowingChannel = await followedQuery.first();

    dispatch({
      type: "SET_FOLLOW_STATUS",
      payload: !!isFollowingChannel,
    });
  };

  useEffect(() => {
    fetchChannelData();
    fetchFollowingStatus();
  }, [channelId, account]);

  const handlePostCompletion = async (newPostInChannel) => {
    try {
      const toastContent = <ChannelToastContent channel={state.currentChannel} />;

      window.emitter.emit("SEAM_EVENT_ADDED_TO_COLLECTION", toastContent);
    } catch (error) {
      console.error("Error optimistically updating with new savedPostInChannel", error);
    }
  };

  const handleImageUpload = (url) => {
    if (state.currentChannel) {
      state.currentChannel.set("headerImage", url);
      state.currentChannel.save()
        .then(() => {
          dispatch({
            type: "SET_CHANNEL_DATA",
            payload: { channelPicture: url },
          });
        })
        .catch((error) => {
          console.error("Failed to save channel image:", error);
        });
    }
  };

  const handleFollow = async () => {
    try {
      await SeamChannelRepository.subscribeToChannel(state.currentChannel, account, () => {
        refreshAccount();
        dispatch({
          type: "SET_FOLLOW_STATUS",
          payload: true,
        });
      }, (error) => {
        console.error("Error subscribing to channel:", error);
      });
    } catch (error) {
      console.error("Subscription error:", error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await SeamChannelRepository.unsubscribeToChannel(state.currentChannel, account, () => {
        refreshAccount();
        dispatch({
          type: "SET_FOLLOW_STATUS",
          payload: false,
        });
      }, (error) => {
        console.error("Error unsubscribing from channel:", error);
      });
    } catch (error) {
      console.error("Unsubscription error:", error);
    }
  };

  const fetchPosts = async (skip, limit) => {
    if (!state.currentChannel || !state.currentChannel.id) return [];
    const channelQuery = new Parse.Query('PostInChannel');
    channelQuery.equalTo('channel', state.currentChannel);
    channelQuery.descending('createdAt');
    channelQuery.include('post');
    channelQuery.include('post.author');
    channelQuery.notEqualTo("isDeleted", true);
    channelQuery.limit(limit);
    channelQuery.skip(skip);
    try {
      const results = await channelQuery.find();
      return results;
    } catch (error) {
      console.error("Error fetching posts: ", error);
      return [];
    }
  };

  const HeaderComponent = ({ context }) => (
    <ChannelFeedHeader
      state={state}
      isChannelCreator={isChannelCreator}
      handleImageUpload={handleImageUpload}
      loading={state.loadingChannelData}
      loadingPosts={context.state.isLoading}
      loadedPosts={context.state.loadedPosts}
      openModal={() => setIsModalOpen(true)}
    />
  );

  const leftAction = () => {
    if (state.currentChannel && state.currentChannel.isNew()) {
      const confirmLeave = window.confirm(
        "The collection hasn't been saved and will be deleted unless you edit it. Are you sure you want to leave?"
      );
      if (confirmLeave) {
        history.go(-1);
      } else {
        // Stay on the page
      }
    } else {
      history.go(-1);
    }
  };

  // Prepare background style
  const channelBackground = state.currentChannel?.get("backgroundImage");
  let backgroundStyle;

  if (channelBackground) {
    backgroundStyle = { backgroundImage: `url(${channelBackground})`, backgroundColor: "white" };
  } else if (state.color2) {
    backgroundStyle = { backgroundImage: `linear-gradient(to bottom, ${state.color1}, ${state.color2})`, backgroundColor: "white" };
  } else {
    backgroundStyle = { backgroundColor: state.color1 ?? "white" };
  }

  const seoTitle = `${state.currentChannel?.get("emoji")} ${state.currentChannel?.get("name")}`;
  const seoDescription = `Start collecting posts from ${state.currentChannel?.get("name")} today!`;
  const seoImage = `https://storage.googleapis.com/seam-social-posts/channels/${state.currentChannel?.id}.jpg`;

  return (
    <IonPage className="profilePage w-full h-full" style={{ marginTop: 'calc(-1 * env(safe-area-inset-top))', height: 'calc(100vh + env(safe-area-inset-top)' }}>
      <Seo
        title={seoTitle}
        description={seoDescription}
        author={"@" + state.currentChannel?.get('creator')?.get("objectId")}
        imageURL={seoImage}
        url={"https://www.seam.so/collection/" + state.currentChannel?.id}
      />
      <Header
        isActivePage={true}
        leftIcon={!takingScreenshot && (
          <ChevronLeft
            className={`py-2 px-6 fill-seam-black cursor-pointer fill-seam-black`}
          />
        )}
        rightAction={() => { }}
        leftAction={leftAction}
        leftActionOnMobileApp={true}
        rightIcon={
          state.currentChannel && account && (
            <div className="relative">
              {isChannelCreator ? (
                <>
                  <SeamComposer
                    composerTitle={"Post something to your collection"}
                    communityName={state.currentChannel.get("name")}
                    communityId={account.get("profileId")}
                    currentCard={undefined}
                    channel={state.currentChannel}
                    completion={handlePostCompletion}
                  />
                </>
              ) : (
                <button
                  onClick={state.isFollowing ? handleUnfollow : handleFollow}
                  className="py-2 px-4 bg-seam-blue text-white rounded-[38px]"
                >
                  <span className="flex flex-row items-center justify-center space-x-2">
                    <PersonAddAlt1 className="fill-white w-4 h-4" />
                    <h4>{state.isFollowing ? "Unfollow" : "Follow"}</h4>
                  </span>
                </button>
              )}
            </div>
          )
        }
        centerIcon={
          <Typography variant="h3" className={`text-seam-${state.fontColor}`}>
            {state.currentChannel?.get("emoji")}&nbsp;{state.currentChannel?.get("name") || ''}
          </Typography>
        }
        includePadding={true}
        scrollToTop={scrollToTop}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <div className={`absolute top-0 w-full`}
          style={{
            ...backgroundStyle,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: 'calc(100% + env(safe-area-inset-top))',
            marginTop: 'calc(-1 * env(safe-area-inset-top))',
            zIndex: -1
          }}
        />
        <DesktopSidebarWrapper>
          <div className="h-full w-full overflow-y-auto flex items-start justify-start" style={{ paddingTop: 'calc(env(safe-area-inset-top)' }}>
            {state.currentChannel?.get("isDeleted") ? (
              <div className="h-full w-full flex items-center justify-center">
                <Typography variant="h2" className="text-[#8A8A8A]">
                  This collection has been deleted.
                </Typography>
              </div>
            ) : (
              <Feed
                ref={feedRef}
                fetchPosts={fetchPosts}
                Header={HeaderComponent}
                itemType="PostInChannel"
                onPostsLoaded={(posts) => setHasLoadedPosts(posts.length > 0)}
                dependencies={[state.currentChannel]}
              />
            )}
          </div>
          {isModalOpen && <IonModal
            isOpen={isModalOpen}
            onDidDismiss={() => setIsModalOpen(false)}
            swipeToClose={false}
            canDismiss={true}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
          >
            <ChannelPageModal
              currentChannel={state.currentChannel}
              isChannelCreator={isChannelCreator}
              state={state}
              dispatch={dispatch}
              closeModal={() => setIsModalOpen(false)}
            />
          </IonModal>}
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default ChannelPage;