import { useRef } from "react";
import { Stack } from "@mui/material";
import UiKitLinkify from "../../Comment/Linkify";
import BlockFactory from '../../Block-SDK/src/blocks/BlockFactory';
import FeedItemOptions from "../../Discover/FeedItemOptions";
import { formatPostDate } from "../../utils/formatPostDate";
import SeamUserItem from "../../components/SeamUserItem";
import { useSeamNavigator } from "../../Navigation/SeamNavigatorContext";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { useSeamUser } from "../../utils/SeamUserContext";
import { BlockTypes } from "../../Block-SDK/src/blocks/types";
import Parse from 'parse';
import FeedErrorBoundary from "../../components/FeedErrorBoundary";

export default function SeamUserFeedItem(props) {
  const { post, collection, postInCollection, isOnChannelPage = false, takingScreenshot = false } = props;
  const seamNavigator = useSeamNavigator();
  const postData = post.get("postData");
  const posterId = post.get("author")?.get("userId");
  const { account } = useSeamUser();
  const ownerAccount = post?.get("author");
  const isOwnerPost = (posterId === ownerAccount?.id) && (postData.communityId === ownerAccount?.get("profileId") || postData.communityName === "their profile" || postData.communityName === "Discovery Feed"); // is this a user update on their own wall
  const isGroupPost = post.get("groupLocationId") !== undefined;
  const destination = collection?.id;
  const displayName = collection?.get("emoji") + " " + collection?.get("name");
  const authorAccount = post.get("author");
  const postCreationDate = post.createdAt;
  const timeSincePost = formatPostDate(postCreationDate);
  const timeSinceCollection = formatPostDate(postInCollection?.get("createdAt"));
  const postOptionsButton = takingScreenshot ? null : <FeedItemOptions post={post} collection={collection} postInCollection={postInCollection} ownerAccount={ownerAccount} isOnChannelPage={isOnChannelPage} />;
  const showDestination = !isOwnerPost && !isGroupPost;
  const ref = useRef(null);

  const authorProfilePhoto = authorAccount?.get("profilePhoto");
  const authorProfileName = authorAccount?.get("profileId");

  const renderPostHeader = () => {
    return (
      <h3 className="text-seam-black hover:underline truncate text-ellipsis overflow-hidden max-w-full whitespace-nowrap">
        {authorProfileName}
      </h3>
    );
  };

  const renderPostInfo = () => {
    if (takingScreenshot) {
      return null;
    }
    const collectionTimeInfo = <span className="flex-shrink-0 text-gray-500">&nbsp;· {timeSinceCollection}</span>;
    if (showDestination) {
      return (
        <div className="flex items-center mt-2 text-xs font-normal text-gray-500">
          {collection && <div className="flex truncate">
            <span>Collected into </span>
            <span
              onClick={() => { seamNavigator.navigateTo(`collection/${destination}`, collection); }}
              className="text-blue-500 hover:underline cursor-pointer ml-1 truncate"
            >
              {displayName}
            </span>
          </div>}
          {postInCollection && collectionTimeInfo}
        </div>
      );
    } else {
      return (
        <div className="text-gray-500 text-xs font-normal flex items-center mt-2">
          Collected {collectionTimeInfo}
        </div>
      );
    }
  };

  const renderPostSubtitle = () => {
    // Determine the block type and printable name
    let blockType, printableBlockName;
    if (postData.blockData !== undefined) {
      blockType = postData.blockData.type;
      printableBlockName = BlockFactory.getPrintableBlockName(postData.blockData);
    } else {
      blockType = "Text";
      printableBlockName = "Text";
    }
    const subtitleText = `${printableBlockName} Miniapp`;
    const postTimeInfo = <span className="flex-shrink-0 text-gray-500">&nbsp;· {timeSincePost}</span>;

    const onSubtitleClick = () => {
      const isMiniappOwned = account?.get("unlockedMiniapps")?.includes(blockType);
      const isDefaultMiniapp = BlockTypes[blockType].createdBy === "seam";
      if (isMiniappOwned || isDefaultMiniapp) {
        window.emitter.emit("SEAM_POST_BLOCKTYPE_CLICK", blockType);
      } else {
        window.emitter.emit("SEAM_POST_BLOCKTYPE_UNLOCK", blockType);
      }
      mixpanel.track("block attribution clicked");
    };

    return (
      <div
        onClick={onSubtitleClick}
        className="text-gray-500 text-xs normal-case font-normal leading-normal select-none"
      >
        {subtitleText}
        {postTimeInfo}
      </div>
    );
  };

  const update = (data) => {
    Parse.Cloud.run("updatePostModel", { postId: post.id, updatedData: data })
  }

  const blockData = postData.blockData;

  try {
    return (
      <Stack direction={"column"} spacing={0} width={"100%"} height={"100%"}>
        <SeamUserItem
          avatar={authorProfilePhoto}
          title={renderPostHeader()}
          subtitle={renderPostSubtitle()}
          link={`user/${authorProfileName}`}
          actions={postOptionsButton}
          timeSince={timeSincePost}
          postText={postData.text}
        />
        {renderPostInfo()}
        {postData.text && (
          <div className="text-black text-base font-normal leading-5 mt-2">
            <UiKitLinkify>{postData.text}</UiKitLinkify>
          </div>
        )}
        {blockData !== undefined &&
          <div className="flex items-center justify-center mt-2 h-auto" ref={ref}>
            <FeedErrorBoundary>
              {BlockFactory.getFeedComponent(blockData, update)}
            </FeedErrorBoundary>
          </div>}
      </Stack>
    );
  } catch (error) {
    console.log(error);
    console.log("unable to display post " + post.id);
    console.log(post);
    Sentry.captureException("feed is crashing!!!");
    return <></>;
  }
}