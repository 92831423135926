import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { BadgeTypes } from "../Badges/BadgeTypes";
import QuestManager from "../Quests/QuestManager";
import { QuestTypes } from "../Quests/QuestTypes";

function ProfilePageHeader({ creatorAccount, profileCustomizations, isSelfProfile }) {
  const seamNavigator = useSeamNavigator();
  const profilePicture = creatorAccount.get("profilePhoto");
  const badges = creatorAccount.get("badges");
  const [collectionsFollowed, setCollectionsFollowed] = useState(0);

  // Fetch data from channels and calculate subscribers, posts, and collections created based on creatorAccount
  useEffect(() => {
    const fetchFollowedChannels = async () => {
      const relation = creatorAccount.relation("followedChannels"); 
      const query = relation.query();
      const followedChannelsCount = await query.count(); 
      setCollectionsFollowed(followedChannelsCount);

      const isCollectionsQuestCompleted = QuestManager.isQuestCompleted(QuestTypes.collections.name, creatorAccount);

      if (!isCollectionsQuestCompleted && isSelfProfile && followedChannelsCount >= 25) {
        QuestManager.completeQuest(QuestTypes.collections, followedChannelsCount, creatorAccount);
      }
    };

    if (creatorAccount) {
      fetchFollowedChannels();
    }
  }, [creatorAccount, profilePicture]); 

  const renderBadges = () => {
    return badges?.map((badgeEarned, index) => {
      const badgeType = BadgeTypes[badgeEarned.type];
      if (!badgeType) return null; // Skip if the badge type is not found

      return (
        <img
          key={index}
          src={badgeType.imageURL}
          alt={badgeType.displayTitle}
          title={badgeType.displayTitle} 
          className="w-[24px] h-[24px] mx-1" 
        />
      );
    });
  };

  const textColor = profileCustomizations?.get("fontColor") || "black";
  return (
    <div className={`flex flex-col items-center space-y-6 justify-between w-full h-auto`} style={{color: textColor}}>
      {/* Avatar and username */}
      <Avatar
        src={profilePicture}
        alt="Profile"
        className={`w-[120px] h-[120px] rounded-full border-2 border-white`}
      />
      <h1 className="leading-tight truncate max-w-xs">
        @{creatorAccount.get("profileId")}
      </h1>

      {/* Stats Section */}
      <div className="flex flex-col items-center justify-center w-full h-auto">
        <span className="flex flex-row items-center justify-center space-x-2">
          <span className="flex flex-row items-center justify-center" onClick={() => {seamNavigator.navigateTo('/friends/' + creatorAccount.get("profileId"), creatorAccount)}}>
            <h4>
              {creatorAccount.get("friendCount")}
            </h4>
            &nbsp;
            <h4 style={{ opacity: '50%' }}>
              Friends
            </h4>
          </span>
          <h4 className="w-[18px] h-4 text-center">•</h4>
          <span className="flex flex-row items-center justify-center" onClick={() => {seamNavigator.navigateTo('/followed/' + creatorAccount.get("profileId"), creatorAccount)}}>
            <h4>
              {collectionsFollowed}
            </h4>
            &nbsp;
            <h4 style={{ opacity: '50%' }}>
              Collections Followed
            </h4>
          </span>
        </span>
      </div>

      {/* Badge Bar */}
      {badges && badges.length > 0 && (
      <div onClick={() => seamNavigator.navigateTo("/awards/" + creatorAccount.get("profileId"))} className="w-auto py-1 px-2 h-auto max-h-[24px] flex flex-row items-center justify-center rounded-[24px]">
        {renderBadges()}
      </div>
      )}
    </div>
  );
}

export default ProfilePageHeader;