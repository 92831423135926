import { useMobile } from "../utils/MobileContext";
import { useSeamUser } from "../utils/SeamUserContext"
import DesktopSidebarWrapper from "../components/DesktopSidebarWrapper";
import SeamUserItem from "../components/SeamUserItem";
import { Virtuoso } from "react-virtuoso";
import { SeamNavigatorContext, useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import Header from "../Navigation/Header";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { IonPage, IonContent } from "@ionic/react";
import { ChevronLeft } from "react-feather";
import Parse from "parse";
import { getProcessedChannelPhotoURL, getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import SeamStar from "../assets/seam-star.png";

const COLLECTIONS_PER_PAGE = 20;

const FollowedPage = () => {
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const { contextObject } = useSeamNavigator();
  const history = useHistory();
  const { profileId } = useParams();
  const isOwnProfile = account ? account.get("profileId") === profileId : false;
  const [creatorAccount, setCreatorAccount] = useState(null);
  const pageTitle = isOwnProfile ? "Your Followed Collections" : `${creatorAccount.get("username")}'s Followed Collections`;
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const seamNavigator = useSeamNavigator();

  useEffect(() => {
    const fetchCreatorAccount = async () => {
      if (isOwnProfile) {
        setCreatorAccount(account);
        return;
      }
      if (contextObject?.get("profileId") === profileId) {
        setCreatorAccount(contextObject);
        return
      }
      // Fetch the creator's account based on the profileId
      try {
        const accountQuery = new Parse.Query("Account");
        accountQuery.equalTo("profileId", profileId);
        const result = await accountQuery.first();
        if (result) {
          setCreatorAccount(result);
        } else {
          console.error("Creator account not found");
          // Handle the case where the creator account is not found
        }
      } catch (error) {
        console.error("Error fetching creator account:", error);
        // Handle fetching error
      }
    };
    fetchCreatorAccount();
  }, [account, profileId, isOwnProfile]);

  const leftIcon = <ChevronLeft color="black" className="py-2 px-6 cursor-pointer" />;
  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  useEffect(() => {
    if (creatorAccount) {
      loadMoreCollections(0);
    }
  }, [profileId, creatorAccount]);

  const loadMoreCollections = async (startIndex) => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const collectionsRelation = creatorAccount.relation("followedChannels");
      const query = collectionsRelation.query();
      query.skip(startIndex);
      query.limit(COLLECTIONS_PER_PAGE);
      query.descending("updatedAt")
      query.notEqualTo("isDeleted", true);
      const result = await query.find();
      if (result.length < COLLECTIONS_PER_PAGE) {
        setHasMore(false);
      }
      setCollections((prev) => [...prev, ...result]);
    } catch (error) {
      console.error("Failed to fetch collections:", error);
    } finally {
      setLoading(false);
    }
  };

  function Footer() {
    return (
      <div id={"footer"} className="w-auto h-[128px]"></div>
    )
  }
  
  const renderItem = (index, collection) => {
    const creator = collection?.get('creator');
    creator.fetch();
    return (
      <div className="p-4 w-full max-w-[720px] mx-auto">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-between justify-center w-full">
            <img src={getProcessedChannelPhotoURL(collection?.get("headerImage") || SeamStar )} className="w-10 h-10" />
            <div className="flex flex-col ml-4 items-start justify-between flex-grow min-w-0">
              <div
                onClick={() => {
                  seamNavigator.navigateTo(`/collection/${collection.id}`);
                }}
                className="w-full flex h-[24px] truncate cursor-pointer hover:underline"
              >
                <h3>{collection.get("emoji")}&nbsp;{collection.get("name")}</h3>
              </div>
              <div className="text-gray-600 h-[16px] truncate">
                <h4>Curated by: @{creator?.get("profileId")}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <IonPage>
      <Header 
        isActivePage={true}
        leftIcon={leftIcon}
        rightIcon={null}
        leftAction={leftAction}
        centerIcon={<h3 className="w-full truncate">{pageTitle}</h3>}
      />
      <IonContent>
        <DesktopSidebarWrapper>
          <div className="flex flex-col w-full h-full">
            <Virtuoso
              style={{ height: window.innerHeight - 56 }}
              className={`ion-content-scroll-host`}
              data={collections}
              endReached={() => loadMoreCollections(collections.length)}
              overscan={3}
              itemContent={renderItem}
              components={
                {
                  Footer: Footer,
                }
              }
            />
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default FollowedPage;