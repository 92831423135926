import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonPage, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { SeamUserProvider } from '../utils/SeamUserContext';
import MobileBottomNav from './MobileBottomNav';
import Inbox from '../Inbox';
import UserProfilePage from '../Profile/UserProfilePage';
import PostDetailPage from '../PostDetailPage';
import GroupPage from '../Groups/GroupPage';
import SearchPage from '../Discover/SearchPage';
import MobileQuestsPage from '../MobileQuestsPage';
import CommentDetailPage from '../Comment/CommentDetailPage';
import AwardsPage from '../Routes/AwardsPage';
import Admin from '../Routes/Admin';
import FriendsPage from '../Routes/FriendsPage';
import CardPage from '../Routes/CardPage';
import ChannelPage from '../Channels/ChannelPage';
import FollowedPage from '../Routes/FollowedPage';
import Parse from 'parse';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css'
import { Capacitor } from '@capacitor/core';
import { SeamNavigatorProvider } from './SeamNavigatorContext';
import MyProfilePage from '../MyProfilePage';
import MobileLandingPage from '../MobileOnboarding/MobileLandingPage';
import LandingPage from '../LandingPage';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import InternalPage, { SeamPageType } from '../InternalPage';
import PostMetaSEOImage from '../PostMetaSEOImage';
import AppUrlListener from './AppUrlListener';
import ToastNotification from './ToastNotification';
import ChannelMetaSEOImage from '../ChannelMetaSEOImage';
import ProfileMetaSEOImage from '../ProfileMetaSEOImage';

function RootTabs() {
  const platform = Capacitor.getPlatform();
  const isMobile = platform === "ios" || platform === "android";
  const isAuthenticated = Parse.User.current() !== undefined;

  useEffect(() => {
    mixpanel.track("app launched")
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>
        <IonPage id="main">
          <SeamNavigatorProvider>
            <SeamUserProvider>
              <MobileBottomNav>
                <IonRouterOutlet>
                  {/* Mobile Routes */}
                  <Route exact path="/" render={() => <InternalPage type={SeamPageType.Landing} />} />
                  <Route exact path="/homeTab" render={() => isMobile ? <InternalPage type={SeamPageType.Home} /> : <Redirect to={"/home"} />} />
                  <Route exact path="/inboxTab" render={() => isMobile ? <Inbox /> : <Redirect to={"/inbox"} />} />
                  <Route exact path="/notificationsTab" render={() => isMobile ? <InternalPage type={SeamPageType.Notifications} /> : <Redirect to={"/notifications"} /> } />
                  <Route exact path="/profileTab" render={() => <MyProfilePage /> } />
                  <Route exact path={['/homeTab/user/:username', '/profileTab/user/:username', '/inboxTab/user/:username', '/notificationsTab/user/:username']} render={({ match }) => <UserProfilePage username={match.params.username} takingScreenshot={false} />} />
                  <Route exact path={['/homeTab/post/:postId', '/profileTab/post/:postId', '/inboxTab/post/:postId', "/notificationsTab/post/:postId"]} render={() => <PostDetailPage />} />
                  <Route exact path={['/homeTab/comment/:commentId', '/profileTab/comment/:commentId', '/inboxTab/comment/:commentId', "/notificationsTab/comment/:commentId"]} render={() => <CommentDetailPage />} />
                  <Route exact path="/login" render={() => { if (isMobile) { return <MobileLandingPage /> } else { return <LandingPage /> } }} />
                  <Route exact path={['/homeTab/search/:tagName', '/profileTab/search/:tagName', '/inboxTab/search/:tagName', '/notificationsTab/search/:tagName']} render={() => <SearchPage />} />
                  <Route exact path={"/inboxTab/space/:groupId"} render={() => <GroupPage />} />
                  <Route exact path="/homeTab/quest/quests" render={() => isMobile ? <MobileQuestsPage /> : <Redirect to={"/quest/quests"} /> } />
                  <Route exact path={['/homeTab/:pageName', '/profileTab/:pageName', '/inboxTab/:pageName', '/notificationsTab/:pageName']} render={({ match }) => <CardPage pageName={match.params.pageName} />} />
                  <Route exact path={['/homeTab/collection/:channelId', '/profileTab/collection/:channelId', '/inboxTab/collection/:channelId', '/notificationsTab/collection/:channelId']} render={({ match }) => <ChannelPage channelId={match.params.channelId} takingScreenshot={false} />} />
                  <Route exact path={["/profileTab/friends/:profileId", "/homeTab/friends/:profileId", "/notificationsTab/friends/:profileId"]} render={() => <FriendsPage />} />
                  <Route exact path={["/profileTab/awards/:profileId", "/homeTab/awards/:profileId", "/notificationsTab/awards/:profileId"]} render={() => <AwardsPage />} />       
                  <Route exact path={["/profileTab/followed/:profileId", "/homeTab/followed/:profileId", "/notificationsTab/followed/:profileId"]} render={() => <FollowedPage />} />                
                  {/* Desktop Routes */}
                  <Route exact path="/home" render={() => <InternalPage type={SeamPageType.Home} />} />
                  <Route exact path="/inbox" render={() => <InternalPage type={SeamPageType.Inbox} />} />
                  <Route exact path="/notifications" render={() => <InternalPage type={SeamPageType.Notifications} />} />
                  <Route exact path="/admin" render={() => <Admin />} />
                  <Route exact path="/user/:username" render={({ match }) => isAuthenticated ? <UserProfilePage username={match.params.username} takingScreenshot={false} /> : <Redirect to={"/login"} />} />
                  <Route exact path="/friends/:profileId" render={() => isAuthenticated ? <FriendsPage /> : <Redirect to={"/login"} />} />
                  <Route exact path="/awards/:profileId" render={() => isAuthenticated ? <AwardsPage /> : <Redirect to={"/login"} />} />
                  <Route exact path="/followed/:profileId" render={() => isAuthenticated ? <FollowedPage /> : <Redirect to={"/login"} />} />
                  <Route exact path="/quest/quests" render={() => isAuthenticated ? <MobileQuestsPage /> : <Redirect to={"/login"} />} />
                  <Route exact path="/post/:postId" render={() => <PostDetailPage />} />
                  <Route exact path="/preview/post/:postId" render={() => <PostMetaSEOImage />} />
                  <Route exact path="/preview/user/:profileId" render={() => <ProfileMetaSEOImage />} />
                  <Route exact path="/preview/collection/:channelId" render={() => <ChannelMetaSEOImage />} />
                  <Route exact path="/comment/:commentId" render={() => <CommentDetailPage />} />
                  <Route exact path="/space/:groupId" render={() => <InternalPage type={SeamPageType.Group} />} />
                  <Route exact path="/search/:tagName" render={() => <InternalPage type={SeamPageType.Search} />} />
                  <Route exact path="/collection/:channelId" render={({ match }) => <ChannelPage channelId={match.params.channelId} takingScreenshot={false} />} />
                  <Route render={() => <CardPage pageName={undefined}/> }/>
                </IonRouterOutlet>
              </MobileBottomNav>
              <ToastNotification />
            </SeamUserProvider>
          </SeamNavigatorProvider>
        </IonPage>
      </IonReactRouter>
    </IonApp>
  );
}

export default RootTabs;