import React, { createRef, memo, useRef, useState } from "react";
import DiscoveryFeed from "../Discover/DiscoveryFeed";
import { useSeamUser } from "../utils/SeamUserContext";
import {
  IonPage,
  IonContent,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import Header from "../Navigation/Header";
import { Search } from "react-feather";
import SeamComposer from "../Composer/SeamComposer";
import useSearchReducer from "../utils/useSearchReducer";
import DesktopSidebarWrapper from "../components/DesktopSidebarWrapper";
import { Button } from "@mui/material";
import AdminFeed from "../Groups/AdminFeed";

function HomePage() {
  const { account, isAdmin } = useSeamUser();
  const [state, dispatch] = useSearchReducer();
  const [isActivePage, setIsActivePage] = useState(false);
  const [showAdminFeed, setShowAdminFeed] = useState(false);
  const virtuosoRef = useRef(null);

  useIonViewWillEnter(() => {
    setIsActivePage(true);
  });

  useIonViewWillLeave(() => {
    setIsActivePage(false);
  });

  const toggleSearchBar = () => {
    dispatch({ type: "TOGGLE_SEARCH_BAR" });
  };

  const handleSearch = (searchString) => {
    dispatch({ type: "UPDATE_SEARCH_STRING", payload: searchString });
  };

  const scrollToTop = () => {
    virtuosoRef.current?.scrollToTop();
  };

  return (
    <IonPage className="w-full h-full">
      <Header
        isActivePage={isActivePage}
        leftIcon={<Search color="black" className="py-2 px-6 cursor-pointer" />}
        rightIcon={undefined}
        leftAction={toggleSearchBar}
        showSearchBar={state.showSearchBar}
        onSearchSubmit={handleSearch}
        scrollToTop={scrollToTop}
        centerIcon={undefined}
      />
      <IonContent fullscreen={true} scrollY={false} scrollX={false}>
        <DesktopSidebarWrapper>
          <div className="flex flex-col w-full h-full">
            {isAdmin && (
              <div className="w-full flex justify-center my-4">
                <Button onClick={() => setShowAdminFeed(!showAdminFeed)}>
                  {showAdminFeed ? 'Show Discovery Feed' : 'Show Admin Feed'}
                </Button>
              </div>
            )}
            <div className="flex-grow overflow-hidden">
              {showAdminFeed ? (
                <AdminFeed />
              ) : (
                <DiscoveryFeed isActivePage={isActivePage} ref={virtuosoRef} />
              )}
            </div>
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
}

export default memo(HomePage);